import { reactive } from 'vue'

import { useSessionStorage } from '@vueuse/core'

const storageKey = 'mfx-studio:debugMode' as const

export const useDebugMode = () => {
  const enabled = useSessionStorage(storageKey, false)

  const enable = () => {
    enabled.value = true
  }

  const disable = () => {
    enabled.value = false
  }

  return reactive({ enabled, enable, disable })
}
