import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import values from 'lodash/values'

import type r4 from 'fhir/r4'

export const modifyStructureDefinition = (
  original: r4.StructureDefinition,
  modifications: Partial<r4.StructureDefinition>
): r4.StructureDefinition => {
  const target = structuredClone(original)
  const changes = {
    ...modifications,
    resourceType: modifications.resourceType || original.resourceType,
    abstract: modifications.abstract || original.abstract,
    kind: modifications.kind || original.kind,
    name: modifications.name || original.name,
    status: modifications.status || original.status,
    type: modifications.type || original.type,
    url: modifications.url || original.url,
  }

  mergeWith<r4.StructureDefinition, r4.StructureDefinition>(
    target,
    changes,

    (targetValue, changedValue) => {
      if (Array.isArray(targetValue) && Array.isArray(changedValue)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- it's chill, all we care at this point is if it's an array or not, we already know the both objects being merged are of the same type so we're just merging arrays, so no harm no foul.
        return values(
          merge(keyBy(targetValue, 'id'), keyBy(changedValue, 'id'))
        )
      }
    }
  )

  return target
}
