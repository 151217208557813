import { datadogRum } from '@datadog/browser-rum'

/**
 * Records a user action and sends it to 3rd party tools for aggregation.
 *
 * @param name - human readable action name
 * @param context - object with whatever context data you want to track
 */
export const recordAction = (name: string, context?: object) => {
  datadogRum.addAction(name, context)
}
