import { default as debugiPf94B6CepMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/debug.vue?macro=true";
import { default as codeYiO6s7mFiXMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/[id]/code.vue?macro=true";
import { default as configurationVkp2ZWzHzDMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/[id]/configuration.vue?macro=true";
import { default as indexahke7ATWT2Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/index.vue?macro=true";
import { default as editeZFhmhgXGyMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit.vue?macro=true";
import { default as indexdXHTb3drb5Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/index.vue?macro=true";
import { default as _91id_93O3riAMyNoqMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/preview/[id].vue?macro=true";
import { default as indexC1I06GIFkXMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id]/build/index.vue?macro=true";
import { default as questionsu5SRjHg0aAMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id]/build/questions.vue?macro=true";
import { default as _91id_93f8KQypfRYcMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id].vue?macro=true";
export default [
  {
    name: debugiPf94B6CepMeta?.name ?? "debug",
    path: debugiPf94B6CepMeta?.path ?? "/debug",
    meta: debugiPf94B6CepMeta || {},
    alias: debugiPf94B6CepMeta?.alias || [],
    redirect: debugiPf94B6CepMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/debug.vue").then(m => m.default || m)
  },
  {
    name: editeZFhmhgXGyMeta?.name ?? "edit",
    path: editeZFhmhgXGyMeta?.path ?? "/edit",
    children: [
  {
    name: codeYiO6s7mFiXMeta?.name ?? "edit-questionnaires-id-code",
    path: codeYiO6s7mFiXMeta?.path ?? "questionnaires/:id()/code",
    meta: codeYiO6s7mFiXMeta || {},
    alias: codeYiO6s7mFiXMeta?.alias || [],
    redirect: codeYiO6s7mFiXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/[id]/code.vue").then(m => m.default || m)
  },
  {
    name: configurationVkp2ZWzHzDMeta?.name ?? "edit-questionnaires-id-configuration",
    path: configurationVkp2ZWzHzDMeta?.path ?? "questionnaires/:id()/configuration",
    meta: configurationVkp2ZWzHzDMeta || {},
    alias: configurationVkp2ZWzHzDMeta?.alias || [],
    redirect: configurationVkp2ZWzHzDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/[id]/configuration.vue").then(m => m.default || m)
  },
  {
    name: indexahke7ATWT2Meta?.name ?? "edit-questionnaires",
    path: indexahke7ATWT2Meta?.path ?? "questionnaires",
    meta: indexahke7ATWT2Meta || {},
    alias: indexahke7ATWT2Meta?.alias || [],
    redirect: indexahke7ATWT2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit/questionnaires/index.vue").then(m => m.default || m)
  }
],
    meta: editeZFhmhgXGyMeta || {},
    alias: editeZFhmhgXGyMeta?.alias || [],
    redirect: editeZFhmhgXGyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdXHTb3drb5Meta?.name ?? "index",
    path: indexdXHTb3drb5Meta?.path ?? "/",
    meta: indexdXHTb3drb5Meta || {},
    alias: indexdXHTb3drb5Meta?.alias || [],
    redirect: indexdXHTb3drb5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93O3riAMyNoqMeta?.name ?? "preview-id",
    path: _91id_93O3riAMyNoqMeta?.path ?? "/preview/:id()",
    meta: _91id_93O3riAMyNoqMeta || {},
    alias: _91id_93O3riAMyNoqMeta?.alias || [],
    redirect: _91id_93O3riAMyNoqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/preview/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93f8KQypfRYcMeta?.name ?? "resources-id",
    path: _91id_93f8KQypfRYcMeta?.path ?? "/resources/:id()",
    children: [
  {
    name: indexC1I06GIFkXMeta?.name ?? "resources-id-build",
    path: indexC1I06GIFkXMeta?.path ?? "build",
    meta: indexC1I06GIFkXMeta || {},
    alias: indexC1I06GIFkXMeta?.alias || [],
    redirect: indexC1I06GIFkXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id]/build/index.vue").then(m => m.default || m)
  },
  {
    name: questionsu5SRjHg0aAMeta?.name ?? "resources-id-build-questions",
    path: questionsu5SRjHg0aAMeta?.path ?? "build/questions",
    meta: questionsu5SRjHg0aAMeta || {},
    alias: questionsu5SRjHg0aAMeta?.alias || [],
    redirect: questionsu5SRjHg0aAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id]/build/questions.vue").then(m => m.default || m)
  }
],
    meta: _91id_93f8KQypfRYcMeta || {},
    alias: _91id_93f8KQypfRYcMeta?.alias || [],
    redirect: _91id_93f8KQypfRYcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/pages/resources/[id].vue").then(m => m.default || m)
  }
]