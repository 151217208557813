import type { MigrationHandler } from './defineMigrationHandler'
import type { MFXStudioAPI } from './setup'

import handleCopyLocalStorage from './migrationHandlers/2023-10-06-copy-local-storage'

/**
 * List of all migrations which should be run on app start.
 */
const migrationHandlers: MigrationHandler[] = [handleCopyLocalStorage]

/**
 * Performs all pending migrations on the user's device.
 */
export const performMigrations = async (api: MFXStudioAPI) => {
  for (const handleMigration of migrationHandlers) {
    // eslint-disable-next-line no-await-in-loop -- Because one migration may depend on another we must do them sequentially.
    await handleMigration(api)
  }
}
