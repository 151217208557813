import type { State as SourcesStoreState } from '@/stores/sources'

import { defineMigrationHandler } from '../defineMigrationHandler'

const isLocalStorageData = (input: unknown): input is SourcesStoreState => {
  const subject = input as Partial<SourcesStoreState>

  if (!subject || !Array.isArray(subject.questionnaires)) {
    return false
  }

  return subject.questionnaires.every((maybeSource) => {
    return maybeSource.id && maybeSource.jsonString
  })
}

const getLocalStorageData = () => {
  try {
    const data = JSON.parse(
      window.localStorage.getItem('sources') || ''
    ) as unknown

    if (!isLocalStorageData(data)) {
      throw new Error()
    }

    return data
  } catch (error) {
    return undefined
  }
}

/**
 * Copies the user's legacy localStorage data to the PouchDB table.
 */
export default defineMigrationHandler({
  name: '2023-10-06-copy-local-storage',
  status: 'in-development',
  async up(api) {
    const legacyData = getLocalStorageData()

    if (!legacyData) {
      return
    }

    for (const source of legacyData.questionnaires) {
      // eslint-disable-next-line no-await-in-loop
      const resource = await api.Resources.create({
        label: source.displayName,
        type: 'Questionnaire',
      })

      const fallbackURL = `https://mfx-studio.medicalformsx.com/resources/${resource._id}`

      // eslint-disable-next-line no-await-in-loop
      const resourceVersion = await api.ResourceVersions.create({
        resourceID: resource._id,
        url:
          source.jsonObject && 'url' in source.jsonObject
            ? String(source.jsonObject?.url)
            : fallbackURL,
        version:
          source.jsonObject && 'version' in source.jsonObject
            ? String(source.jsonObject?.version)
            : '0.0.0',
      })

      // eslint-disable-next-line no-await-in-loop
      await api.ResourceVersionIterations.create({
        resourceVersionID: resourceVersion._id,
        source: source.jsonString,
        parseable: source.isValidJSON,
        sourceObject: source.jsonObject,
      })
    }
  },
})
