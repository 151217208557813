import type {
  Database,
  RecordID,
  ResourceVersionIteration,
  ResourceVersion,
  Resource,
  Createable,
} from './schema'

import { generateRecordMeta } from './utils'

export const setup = (database: Database) => {
  return {
    $$database: database,
    get<RecordType = unknown>(id: RecordID) {
      return database.get<RecordType>(id)
    },
    Resources: {
      on(eventName: 'change', callback: () => unknown) {
        return database.changes({ since: 'now' }).on(eventName, callback)
      },
      get(slug: string) {
        return database.get<Resource>(`Resources_${slug}`)
      },
      all() {
        return database.allDocs<Resource>({
          include_docs: true,
          startkey: 'Resources_',
          endkey: '',
        })
      },
      async create(resource: Createable<Resource>) {
        const response = await database.put({
          ...resource,
          ...generateRecordMeta('Resources'),
        })

        return database.get(response.id)
      },
    },
    ResourceVersions: {
      all({ resourceID }: { resourceID: RecordID }) {
        return database.allDocs({
          include_docs: true,
          startkey: `ResourceVersions_${resourceID}_`,
          endkey: '',
        })
      },
      async create(resourceVersion: Createable<ResourceVersion>) {
        const response = await database.put({
          ...resourceVersion,
          ...generateRecordMeta('ResourceVersions', resourceVersion.resourceID),
        })

        return database.get<ResourceVersion>(response.id)
      },
    },
    ResourceVersionIterations: {
      all({ resourceVersionID }: { resourceVersionID: RecordID }) {
        return database.allDocs({
          include_docs: true,
          startkey: `ResourceVersionIterations_${resourceVersionID}_`,
          endkey: '',
        })
      },
      async create(
        resourceVersionIteration: Createable<ResourceVersionIteration>
      ) {
        const response = await database.put({
          ...resourceVersionIteration,
          ...generateRecordMeta(
            'ResourceVersionIterations',
            resourceVersionIteration.resourceVersionID
          ),
        })

        return database.get<ResourceVersionIteration>(response.id)
      },
    },
  }
}

export type MFXStudioAPI = ReturnType<typeof setup>
