import ShortUniqueId from 'short-unique-id'

import type { TableName, DatabaseRecord, RecordID } from './schema'

const { randomUUID } = new ShortUniqueId({ length: 8 })

/**
 * Generates the shared metadata properties every new DatabaseRecord should contain.
 *
 * @param tableName - Name of the table the record is in.
 * @param relatedID - RecordID for a dependent record. Helps with record lookup.
 */
export const generateRecordMeta = (
  tableName: TableName,
  relatedID?: RecordID
): DatabaseRecord => {
  const id = randomUUID()

  return {
    slug: id,
    _id: [tableName, relatedID, id].filter(Boolean).join('_'),
    createdAt: new Date().toISOString(),
  }
}
