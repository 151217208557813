import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/Questionnaire'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Questionnaire.item.text',
        path: 'Questionnaire.item.text',
        short: 'Display text',
      },
      {
        id: 'Questionnaire.item.type',
        path: 'Questionnaire.item.type',
        short: 'Item type',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.linkId',
        path: 'Questionnaire.item.linkId',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableBehavior',
        path: 'Questionnaire.item.enableBehavior',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.maxLength',
        path: 'Questionnaire.item.maxLength',
        short: 'Maximum number of characters',
      },
      {
        id: 'Questionnaire.item.prefix',
        path: 'Questionnaire.item.prefix',
        short: 'Short label to display before the item text',
      },
    ],
  },
})
